var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('div',{staticClass:"admin_main_block"},[_c('div',{staticClass:"admin_breadcrumb"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/Admin/index' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("代理中心")]),_c('el-breadcrumb-item',[_vm._v("新增BD")])],1)],1)]),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',{attrs:{"gutter":40}},[_c('el-col',{attrs:{"span":24}},[_c('span',{staticClass:"grid-divider"},[_vm._v("基本信息")]),_c('el-divider')],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"真实姓名","prop":"userName","rules":[
              { required: true, message: '请输入真实姓名', trigger: 'blur' },
              {
                min: 2,
                max: 20,
                message: '长度在 2 到 20 个字符',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":_vm.disabled},model:{value:(_vm.form.userName),callback:function ($$v) {_vm.$set(_vm.form, "userName", $$v)},expression:"form.userName"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"身份证号","prop":"idCardNo"}},[_c('el-input',{attrs:{"placeholder":"","disabled":_vm.disabled},model:{value:(_vm.form.idCardNo),callback:function ($$v) {_vm.$set(_vm.form, "idCardNo", $$v)},expression:"form.idCardNo"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"服务区域","prop":"serverArea","rules":[
              { required: true, message: '请选择服务商', trigger: 'blur' } ]}},[_c('el-select',{attrs:{"filterable":"","disabled":_vm.disabled,"placeholder":"请选择"},model:{value:(_vm.form.serverArea),callback:function ($$v) {_vm.$set(_vm.form, "serverArea", $$v)},expression:"form.serverArea"}},_vm._l((_vm.listServPopData),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.serviceName,"value":item.id}})}),1)],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"服务商","prop":"operationArea","rules":[
              { required: true, message: '请选择服务商', trigger: 'blur' } ]}},[_c('el-select',{attrs:{"filterable":"","disabled":_vm.disabled,"placeholder":"请选择"},model:{value:(_vm.form.operationArea),callback:function ($$v) {_vm.$set(_vm.form, "operationArea", $$v)},expression:"form.operationArea"}},_vm._l((_vm.listServPopData),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.serviceName,"value":item.id}})}),1)],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"区域运营中心","prop":"operationCenterId","rules":[
              { required: true, message: '区域运营中心', trigger: 'blur' } ]}},[_c('el-select',{attrs:{"filterable":"","disabled":_vm.disabled,"placeholder":"请选择"},model:{value:(_vm.form.operationCenterId),callback:function ($$v) {_vm.$set(_vm.form, "operationCenterId", $$v)},expression:"form.operationCenterId"}},_vm._l((_vm.listOperPopData),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.userName,"value":item.id}})}),1)],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"手机号码（会员账号）","prop":"phone","rules":[
              {
                required: true,
                message: '请输入手机号码（会员账号）',
                trigger: 'blur',
              },
              {
                min: 11,
                max: 11,
                message: '长度在 11 个字符',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":_vm.disabled},on:{"change":_vm.getUserinfo},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"账号名称","prop":"realName","rules":[
              { required: true, message: '请输入账号名称', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":true},model:{value:(_vm.form.realName),callback:function ($$v) {_vm.$set(_vm.form, "realName", $$v)},expression:"form.realName"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"办公区域","prop":"officeArea","rules":[
              { required: true, message: '请选择办公区域', trigger: 'blur' } ]}},[_c('all-area-list',{attrs:{"data":_vm.form.officeArea,"show":_vm.disabled},on:{"get-all-area-list":_vm.getAllAreaList},model:{value:(_vm.form.officeArea),callback:function ($$v) {_vm.$set(_vm.form, "officeArea", $$v)},expression:"form.officeArea"}})],1)],1),_c('el-col',{staticClass:"hight",attrs:{"span":_vm.span}},[_c('el-form-item',{attrs:{"label":"状态","prop":"enable","rules":[
              { required: true, message: '请选择状态', trigger: 'blur' } ]}},[_c('el-select',{attrs:{"placeholder":"请选择","disabled":_vm.disabled,"clearable":""},model:{value:(_vm.form.enable),callback:function ($$v) {_vm.$set(_vm.form, "enable", $$v)},expression:"form.enable"}},[_c('el-option',{attrs:{"label":"开启","value":"true"}}),_c('el-option',{attrs:{"label":"关闭","value":"false"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('span',{staticClass:"grid-divider"},[_vm._v("上传身份证")]),_c('el-divider')],1),_c('el-col',{attrs:{"span":_vm.span}},[(_vm.showPic)?_c('upload',{attrs:{"title":"身份证人像面","field":"card1","param":_vm.upload.param,"urlPath":_vm.form.idCardImage ? _vm.form.idCardImage[0] : ''},on:{"getUploadFile":_vm.getUploadFile}}):_vm._e()],1),_c('el-col',{attrs:{"span":_vm.span}},[(_vm.showPic)?_c('upload',{attrs:{"title":"身份证国徽面","field":"card2","param":_vm.upload.param,"urlPath":_vm.form.idCardImage && _vm.form.idCardImage.length > 1
                ? _vm.form.idCardImage[1]
                : ''},on:{"getUploadFile":_vm.getUploadFile}}):_vm._e()],1),_c('el-col',{attrs:{"span":_vm.span}},[(_vm.showPic)?_c('upload',{attrs:{"title":"手持身份证照","field":"card3","param":_vm.upload.param,"urlPath":_vm.form.idCardImage && _vm.form.idCardImage.length > 2
                ? _vm.form.idCardImage[2]
                : ''},on:{"getUploadFile":_vm.getUploadFile}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',[(_vm.isEdit)?_c('el-col',{attrs:{"span":24}},[_c('el-card',[_c('el-button',{attrs:{"icon":"el-icon-check","type":"primary"},on:{"click":_vm.isEditBtn}},[_vm._v(" 修改 ")])],1)],1):_c('el-col',{attrs:{"span":24}},[_c('el-card',[_c('el-button',{attrs:{"icon":"el-icon-check","type":"primary"},on:{"click":_vm.back}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"icon":"el-icon-back","type":"primary"},on:{"click":function($event){return _vm.success('form')}}},[_vm._v(" 提交 ")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }